import {} from 'bootstrap-tokenfield'
import 'bootstrap-tokenfield/dist/css/bootstrap-tokenfield.css'

$(document).ready(() => {
  const $input = $('input[data-autocomplete-source]')
  if ($input.length) {
    $input.each(function () {
      const $element = $(this)
      $element.tokenfield({
        autocomplete: {
          source: $element.data('autocomplete-source'),
          delay: 500
        },
        showAutocompleteOnFocus: true
      })
    })
  }
})
