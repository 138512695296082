import {} from 'jquery'
import {} from 'bootstrap-daterangepicker'
import {} from 'flatpickr'
import {} from 'bootstrap-tokenfield'
import './application.css'
import 'bootstrap-daterangepicker/daterangepicker.css'

import RecruiterTools from 'controllers/recruiter_tools/index'
import AdminFilters from 'controllers/admins'
import AutoSuspension from 'controllers/courier_auto_suspension'
import InvoiceFilters from 'controllers/invoices'
import AccountRechargesFilters from 'controllers/account_recharges'
import Pintura from 'controllers/common/pintura'
import ShopCategories from 'controllers/shop_categories'
import ZoneOrderReports from 'controllers/zone_order_reports'
import CorporateCouponTemplates from 'controllers/corporate_coupon_templates'
import GreetingMessages from 'controllers/greeting_messages'
import ShopPpdDetails from 'controllers/shop_ppd_details'
import ContentComponents from 'controllers/content_components'
import { submitRemoteForm } from 'helpers/utils'
import CategoryOrders from 'controllers/category_orders'
import Tags from 'controllers/tags'
import BannerRanks from './controllers/banner_ranks'
import ListingCarouselSection from 'controllers/listing_carousel_section'
import UserSubscriptionsCouponTemplates from 'controllers/user_subscriptions/coupon_templates'
import UserSubscriptionsFreeTrials from 'controllers/user_subscriptions/free_trials'
import SubscriptionManagementPanel from 'controllers/user_subscriptions/management_panels'
import FreeSubscription from 'controllers/user_subscriptions/free_subscriptions'
import AppIcons from './controllers/app_icons'
import SubscriptionPromotion from 'controllers/user_subscriptions/subscription_promotions'
import {} from 'services/autocomplete.js'
import {} from 'services/datepicker.js'

window.submitRemoteForm = submitRemoteForm

// Intercept the jQuery event and emit a custom JS event to decouple application from jQuery.
$(document).on('ajax:success', ({ target }, data) => {
  const event = new CustomEvent('remote:success', {
    detail: data,
    bubbles: true
  })
  target.dispatchEvent(event)
})

// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag "application" %>
//    console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Visit the guide for more information: https://vite-ruby.netlify.app/guide/rails

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

RecruiterTools()
AutoSuspension()

document.addEventListener('DOMContentLoaded', function () {
  AdminFilters()
  InvoiceFilters()
  AccountRechargesFilters()
  ShopCategories()
  ZoneOrderReports()
  Pintura()
  CorporateCouponTemplates()
  GreetingMessages()
  ShopPpdDetails()
  ContentComponents()
  CategoryOrders()
  Tags()
  BannerRanks()
  ListingCarouselSection()
  UserSubscriptionsCouponTemplates()
  UserSubscriptionsFreeTrials()
  SubscriptionManagementPanel()
  FreeSubscription()
  AppIcons()
  SubscriptionPromotion()
})
